import React from "react"

const HeartIcon = ({ className }) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={className}
        fill="none"
    >
        <path
            d="M10.567 19.0534L10.5662 19.0527C8.05943 16.7796 6.01894 14.9259 4.59931 13.1891C3.18518 11.459 2.43945 9.90601 2.43945 8.24251C2.43945 5.52289 4.56167 3.40918 7.27279 3.40918C8.80891 3.40918 10.2915 4.12686 11.257 5.25179L11.6364 5.69386L12.0158 5.25179C12.9813 4.12686 14.4639 3.40918 16.0001 3.40918C18.7112 3.40918 20.8334 5.52289 20.8334 8.24251C20.8334 9.90601 20.0877 11.459 18.6735 13.1891C17.2539 14.9259 15.2134 16.7796 12.7066 19.0527L12.7059 19.0534L11.6364 20.027L10.567 19.0534Z"
            fill={"white"} />
    </svg>

export default HeartIcon;