import React, { Component } from 'react';
import { string, shape, number, object, func } from 'prop-types';
// This MultiTouch lib is used for 2-finger panning.
// which prevents user to experience map-scroll trap, while scrolling the page.
// https://github.com/mapbox/mapbox-gl-js/issues/2618
// TODO: we should add an overlay with text "use two fingers to pan".
import MultiTouch from 'mapbox-gl-multitouch';
import uniqueId from 'lodash/uniqueId';

// import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { circlePolyline } from '../../util/maps';

const mapMarker = () => {
  return new window.mapboxgl.Marker();
};

const circleLayer = (center, mapsConfig, layerId) => {
  const path = circlePolyline(center, mapsConfig.fuzzy.offset).map(
    ([lat, lng]) => [lng, lat]
  );
  return {
    id: layerId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [path],
        },
      },
    },
    paint: {
      'fill-color': mapsConfig.fuzzy.circleColor,
      'fill-opacity': 0.2,
    },
  };
};

const generateFuzzyLayerId = () => {
  return uniqueId('fuzzy_layer_');
};

class SellerSelectLocationMap extends Component {
  constructor(props) {
    super(props);

    this.mapContainer = null;
    this.map = null;
    this.centerMarker = null;
    this.fuzzyLayerId = generateFuzzyLayerId();
    this.draggableMarker = null;
    this.geocoder = null;

    this.updateFuzzyCirclelayer = this.updateFuzzyCirclelayer.bind(this);
    this.handleMarkerDragEnd = this.handleMarkerDragEnd.bind(this);
  }
  componentDidMount() {
    const { center, zoom, mapsConfig, onLocationSelect } = this.props;
    const position = [center.lng, center.lat];

    this.map = new window.mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: position,
      zoom,
      scrollZoom: false,
    });
    this.map.addControl(
      new window.mapboxgl.NavigationControl({ showCompass: false }),
      'top-left'
    );
    this.map.addControl(new MultiTouch());

    if (mapsConfig.fuzzy.enabled) {
      this.map.on('load', () => {
        this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));
      });
    }

    // Always add the draggable marker
    this.draggableMarker = new window.mapboxgl.Marker({ draggable: true })
      .setLngLat(position)
      .addTo(this.map);

    this.draggableMarker.on('dragend', this.handleMarkerDragEnd);

    // Trigger initial location select
    if (onLocationSelect) {
      this.reverseGeocode(center.lng, center.lat);
    }

    this.geocoder = new MapboxGeocoder({
      accessToken: mapsConfig.mapboxAccessToken,
      mapboxgl: window.mapboxgl,
      marker: false,
    });

    this.map.addControl(this.geocoder, 'top-right');

    this.geocoder.on('result', e => {
      const result = e.result;
      const [lng, lat] = result.geometry.coordinates;

      if (this.draggableMarker) {
        this.draggableMarker.setLngLat([lng, lat]);
      }

      this.map.setCenter([lng, lat]);

      if (this.props.onLocationSelect) {
        this.props.onLocationSelect({
          lng,
          lat,
          address: result.place_name,
        });
      }
    });
  }
  componentWillUnmount() {
    if (this.map) {
      this.centerMarker = null;
      if (this.draggableMarker) {
        this.draggableMarker.remove();
      }
      this.map.remove();
      this.map = null;
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.map) {
      return;
    }

    const { center, zoom, mapsConfig } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    // zoom change
    if (zoom !== prevProps.zoom) {
      this.map.setZoom(this.props.zoom);
    }

    const centerChanged =
      lat !== prevProps.center.lat || lng !== prevProps.center.lng;

    if (centerChanged) {
      this.map.setCenter(position);
      if (this.draggableMarker) {
        this.draggableMarker.setLngLat(position);
      }
    }

    // fuzzy circle change
    if (mapsConfig.fuzzy.enabled && centerChanged) {
      if (this.map.loaded()) {
        this.updateFuzzyCirclelayer();
      } else {
        this.map.on('load', this.updateFuzzyCirclelayer);
      }
    }

    if (this.props.onLocationSelect !== prevProps.onLocationSelect) {
      if (this.props.onLocationSelect && !this.draggableMarker) {
        this.draggableMarker = new window.mapboxgl.Marker({ draggable: true })
          .setLngLat([this.props.center.lng, this.props.center.lat])
          .addTo(this.map);

        this.draggableMarker.on('dragend', this.handleMarkerDragEnd);
      } else if (!this.props.onLocationSelect && this.draggableMarker) {
        this.draggableMarker.remove();
        this.draggableMarker = null;
      }
    }
  }
  updateFuzzyCirclelayer() {
    if (!this.map) {
      return;
    }
    const { center, mapsConfig } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    this.map.removeLayer(this.fuzzyLayerId);

    // We have to use a different layer id to avoid Mapbox errors
    this.fuzzyLayerId = generateFuzzyLayerId();
    this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));

    this.map.setCenter(position);
  }
  handleMarkerDragEnd() {
    const { onLocationSelect } = this.props;
    if (onLocationSelect && this.draggableMarker) {
      const { lng, lat } = this.draggableMarker.getLngLat();
      this.reverseGeocode(lng, lat);
    }
  }
  reverseGeocode(lng, lat) {
    const { mapsConfig, onLocationSelect } = this.props;
    const accessToken = mapsConfig.mapboxAccessToken;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          const address = data.features[0].place_name;

          if (this.geocoder) {
            this.geocoder.setInput(address);
          }

          onLocationSelect({ lng, lat, address });
        } else {
          onLocationSelect({ lng, lat, address: 'Address not found' });
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
        onLocationSelect({ lng, lat, address: 'Error fetching address' });
      });
  }
  render() {
    const { containerClassName, mapClassName } = this.props;
    return (
      <div className={containerClassName}>
        <div className={mapClassName} ref={el => (this.mapContainer = el)} />
      </div>
    );
  }
}

SellerSelectLocationMap.defaultProps = {
  address: '',
  center: null,
  onLocationSelect: null,
};

SellerSelectLocationMap.propTypes = {
  address: string, // not used
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number.isRequired,
  mapsConfig: object.isRequired,
  onLocationSelect: func,
};

export default SellerSelectLocationMap;
