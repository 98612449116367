import React, { useMemo } from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ESTIMATED_TAX, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemEstimatedTaxMaybe = props => {
  const { lineItems, intl, page = "" } = props;

  const estimatedTaxLineItem = lineItems?.filter(
    item => item.code === LINE_ITEM_ESTIMATED_TAX
  );

  const tax = useMemo(() => {
    if (estimatedTaxLineItem?.length > 0) {
      const lineTotals = estimatedTaxLineItem?.map(i => i.lineTotal);
      const reducedTotalAmount = lineTotals?.reduce((acc, item) => acc + +item.amount, 0);
      let tax = null;
      if (lineTotals?.[0]) {
        tax = new Money(reducedTotalAmount, lineTotals[0].currency);
      }
      return formatMoney(intl, tax);
    }
    return "";
  }, [estimatedTaxLineItem]);

  if (!estimatedTaxLineItem || !tax) return null;

  if (page === "OrderDetailsPage") {
    return <div className={css.orderTotalMain}>
      <h2 className={css.totalTitle}>
        Tax
      </h2>
      <p className={css.totalPrice}>
        {tax}
      </p>
    </div>
  }

  return estimatedTaxLineItem ? (
    <div className={css.paymentAlignment}>
      <span className={css.maxWidthPayment}>
        Estimated tax
      </span>
      {tax}
    </div>
  ) : null;
};

LineItemEstimatedTaxMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
  page: propTypes.string
};

export default LineItemEstimatedTaxMaybe;
