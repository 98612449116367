// Seller profile constant for identifying store owners
export const STORE_OWNER = 'store_owner';

// Customer profile constant for identifying customers
export const CUSTOMER = 'customer';

// Sharetribe constant for identifying providers (sellers)
export const PROVIDER = 'provider';

/**
 * Get the transaction role based on the user's profile type.
 * Maps the `userType` to the corresponding transaction role:
 * - 'customer' => 'customer'
 * - 'store_owner' => 'provider'
 * If the `userType` is not found, defaults to 'customer'.
 * 
 * @param {string} userType - The type of the user ('customer' or 'store_owner').
 * @returns {string} The mapped transaction role ('customer' or 'provider').
 */
export const getTransactionRole = (userType) => {
    const userTypeMapper = {
        [CUSTOMER]: CUSTOMER,
        [STORE_OWNER]: PROVIDER
    };
    return userTypeMapper[userType] || CUSTOMER;
}

/**
 * Retrieve the customer's profile object from a transaction.
 * Safely accesses the transaction object to get the customer's profile.
 * If the profile is not available, returns an object.
 * 
 * @param {Object} transaction - The transaction object.
 * @returns {Object} The customer's profile object, or an empty object if not available.
 */
export const getCustomerProfileFromTransaction = (transaction) => (
    transaction?.customer?.attributes?.profile || {}
);

/**
 * Retrieve the provider's (seller's) profile object from a transaction.
 * Safely accesses the transaction object to get the provider's profile.
 * If the profile is not available, returns an object.
 * 
 * @param {Object} transaction - The transaction object.
 * @returns {Object} The provider's profile object, or an empty object if not available.
 */
export const getProviderProfileFromTransaction = (transaction) => (
    transaction?.provider?.attributes?.profile || {}
);

export const getOrderNoFromUUID = (uuid) => {
    if (!uuid) return "";
    return `#${(uuid || "")}`
}

/**
 * Get user type from user.
 * @returns {string} Return user type.
 */
export const getUserType = (user) => {
    if (!user)
        return "";
    const userType = user?.attributes?.profile?.publicData?.userType || "";
    return userType;
}

export const OrderDetailsPagePath = '/order/:id';
export const OrderSuccessPath = '/order/:id/success';
