// transactionConstants.js

// Transitions
export const transitions = {
    REQUEST_PAYMENT: 'transition/request-payment',
    INQUIRE: 'transition/inquire',
    REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',
    CONFIRM_PAYMENT: 'transition/confirm-payment',
    EXPIRE_PAYMENT: 'transition/expire-payment',
    MARK_DELIVERED: 'transition/mark-delivered',
    OPERATOR_MARK_DELIVERED: 'transition/operator-mark-delivered',
    MARK_RECEIVED_FROM_PURCHASED: 'transition/mark-received-from-purchased',
    MARK_CANCEL_FROM_PURCHASED_BY_CUSTOMER: 'transition/mark-cancel-by-customer',
    MARK_CANCEL_FROM_PURCHASED_BY_PROVIDER: 'transition/mark-cancel-by-provider',
    AUTO_CANCEL: 'transition/auto-cancel',
    CANCEL: 'transition/cancel',
    MARK_RECEIVED: 'transition/mark-received',
    AUTO_MARK_RECEIVED: 'transition/auto-mark-received',
    DISPUTE: 'transition/dispute',
    OPERATOR_DISPUTE: 'transition/operator-dispute',
    AUTO_CANCEL_FROM_DISPUTED: 'transition/auto-cancel-from-disputed',
    CANCEL_FROM_DISPUTED: 'transition/cancel-from-disputed',
    MARK_RECEIVED_FROM_DISPUTED: 'transition/mark-received-from-disputed',
    AUTO_COMPLETE: 'transition/auto-complete',
    REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
    REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
    REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
    REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
    EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
    EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
    EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
    UPDATE_CHILD_TRANSACTIONS: 'transition/update-child-transactions',
    OPERATOR_PENDING_PARTIAL_REFUND_FROM_DISPUTED: 'transition/operator-pending-partial-refund-from-disputed',
    OPERATOR_MARK_RECEIVED_WITH_PARTIAL_REFUND: 'transition/operator-mark-received-with-partial-refund',
    MARK_RETURN_INITIATED: 'transition/mark-return-initiated',
    PROVIDER_MARK_RETURN_INITIATED: 'transition/provider-mark-return-initiated',
    MARK_RETURN_INITIATED_FROM_REVIEWED: 'transition/mark-return-initiated-from-reviewed',
    PROVIDER_MARK_RETURN_INITIATED_FROM_REVIEWED: 'transition/provider-mark-return-initiated-from-reviewed',
    PROVIDER_MARK_RETURN_APPROVED: 'transition/provider-mark-return-approved',
    PROVIDER_MARK_COMPLETE_RETURN_APPROVED: 'transition/provider-mark-complete-return-approved',
    OPERATOR_MARK_COMPLETE_REFUND_FROM_RETURN: 'transition/operator-mark-complete-refund-from-return',
    PROVIDER_MARK_COMPLETE_RETURN: 'transition/provider-mark-complete-return',
    PROVIDER_MARK_PARTIAL_RETURN: 'transition/provider-mark-partial-return',
    OPERATOR_MARK_PARTIAL_RETURN: 'transition/operator-mark-partial-return',
    OPERATOR_MARK_COMPLETE_RETURN: 'transition/operator-mark-complete-return',
    OPERATOR_MARK_RECEIVED_WITH_RETURN: 'transition/operator-mark-received-with-return',
    EXPIRE_CUSTOMER_RETURN_PERIOD: 'transition/expire-customer-return-period',
    EXPIRE_CUSTOMER_RETURN_PERIOD_AFTER_REVIEWED: 'transition/expire-customer-return-period-after-reviewed',
    CUSTOMER_REVIEWED: "transition/review-by-customer-after-return-expire",
    REVIEW_BY_CUSTOMER_AFTER_RETURN_EXPIRE: "transition/review-by-customer-after-return-expire",
    EXPIRE_CUSTOMER_RETURN_PERIOD_AFTER_RETURN_INITIATED: 'transition/expire-customer-return-period-after-return-initiated'
};

// States
export const states = {
    INITIAL: 'initial',
    INQUIRY: 'inquiry',
    PENDING_PAYMENT: 'pending-payment',
    PAYMENT_EXPIRED: 'payment-expired',
    PURCHASED: 'purchased',
    DELIVERED: 'delivered',
    RECEIVED: 'received',
    DISPUTED: 'disputed',
    CANCELED: 'canceled',
    COMPLETED: 'completed',
    REVIEWED: 'reviewed',
    REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
    PENDING_UPDATE_CHILD_TRANSACTIONS: 'pending-update-child-transactions',
    PENDING_PARTIAL_REFUND: 'pending-partial-refund',
    RETURN_INITIATED: 'return-initiated',
    RETURN_APPROVED: 'return-approved',
    COMPLETE_RETURN: 'complete-return',
    PARTIAL_RETURN: 'partial-return',
    CLOSED: 'closed',
};
