import { currentUserShowSuccess } from "../../ducks/user.duck";
import { denormalisedResponseEntities } from "../../util/data";


// ================ Action types ================ //

export const SAVE_POS_INTEGRATION_REQUEST = 'app/PosIntegrationPage/SAVE_POS_INTEGRATION_REQUEST';
export const SAVE_POS_INTEGRATION_SUCCESS = 'app/PosIntegrationPage/SAVE_POS_INTEGRATION_SUCCESS';
export const SAVE_POS_INTEGRATION_CHANGED = 'app/PosIntegrationPage/SAVE_POS_INTEGRATION_CHANGED';

// ================ Reducer ================ //

const initialState = {
    savePosIntegrationInProgress: false,
    pristineSinceLastSubmit: false,
    posIntegrationChanged: true
};
export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_POS_INTEGRATION_REQUEST:
            return {
                ...state,
                savePosIntegrationInProgress: true,
            };
        case SAVE_POS_INTEGRATION_SUCCESS:
            return { ...state, savePosIntegrationInProgress: false, pristineSinceLastSubmit: true };
        case SAVE_POS_INTEGRATION_CHANGED:
            return { ...state, savePosIntegrationInProgress: false, pristineSinceLastSubmit: false, posIntegrationChanged: false };
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const savePOSIntegrationRequest = () => ({ type: SAVE_POS_INTEGRATION_REQUEST });
export const savePOSIntegrationSuccess = () => ({ type: SAVE_POS_INTEGRATION_SUCCESS });
export const POSIntegrationChanged = () => ({ type: SAVE_POS_INTEGRATION_CHANGED });

/**
 * Make a listings type update request to the API and return the current user.
 */
const requestSavePosValue = params => (dispatch, getState, sdk) => {
    const POSSystem = params.selectedPosValue;
    return sdk.currentUser
        .updateProfile(
            { publicData: { POSSystem } },
            {
                expand: true,
                include: ['profileImage'],
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
            }
        )
        .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
                throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }

            const currentUser = entities[0];
            return currentUser;
        })
        .catch(e => {
            throw e;
        });
};
// ================ Thunks ================ //

/**
 * Save phone number and update the current user.
 */
const savePosSelectedValue = params => (dispatch, getState, sdk) => {
    return (
        dispatch(requestSavePosValue(params))
            .then(user => {
                dispatch(currentUserShowSuccess(user));
                dispatch(savePOSIntegrationSuccess())
            })
            // error action dispatched in requestPosValue
            .catch(e => null)
    );
};
/**
 * save pos integration, actions depend on which data has changed
 */
export const savePosIntegration = params => (dispatch, getState, sdk) => {
    dispatch(savePOSIntegrationRequest());

    const { selectedPosValue } = params
    dispatch(savePosSelectedValue({ selectedPosValue }));
};
/**
 * save change pos integration , actions depend on which data has changed
 */
export const PosIntegrationChange = params => (dispatch, getState, sdk) => {
    dispatch(POSIntegrationChanged())
};