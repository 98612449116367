import React, { useState } from 'react';
import css from '../AddToCartButton/AddToCartButton.module.css';
import { FormattedMessage } from '../../util/reactIntl';

const AddToCartButton = props => {
  const {
    listing,
    selectedListing,
    incrementCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
    openModalOnClick,
    handleAddToFavorites,
    ensuredCurrentUser
  } = props;

  const [localCount, setLocalCount] = useState(1);

  if (isBooking || !showProductOrderForm) {
    return null;
  }

  const checkListingHasVariants = () => {
    const hasVariants = listing?.attributes?.publicData?.has_variants;
    if (typeof hasVariants === 'string') {
      return hasVariants === "true" ? true : false
    }
    return hasVariants;
  }

  
  const listingHasVariants = checkListingHasVariants();
  const isSelectedListingVariant = selectedListing?.id?.uuid !== listing?.id?.uuid;
  const variantSelected = listingHasVariants ? isSelectedListingVariant : true;
  
  const increaseCount = () => setLocalCount(prevCount => prevCount + 1);
  const decreaseCount = () => setLocalCount(prevCount => prevCount - 1);
  
  const currentStock = listing?.currentStock?.attributes.quantity;
  const isMaxItems = currentStock <= localCount;
  const handleAddToCart = () => {
    if ((!isOwnListing) && localCount > 0) {
      incrementCart(localCount);
      openModalOnClick();
    }
  };

  const id = listing?.id?.uuid;
  const isFavorite = ensuredCurrentUser?.attributes?.profile?.privateData?.favorites?.includes(id);
  const toggleFavorites = () => handleAddToFavorites(isFavorite, id);

  // Disable add to cart if no variant is selected for products with variants
  const disableAddToCart = (localCount <= 0 || (listingHasVariants && !variantSelected));

  return (
    <div className={css.addToCartContainer}>
      <div className={css.quantityCountContainer}>
        <div className={css.cartLabel}>{isListingPage && (cartLabel)}</div>
        <div className={css.buttonContainer}>
          <button className={css.smallButton} onClick={decreaseCount} disabled={localCount <= 0}>
            -
          </button>
          <span className={css.countContainer}>{localCount}</span>
          <button className={css.smallButton} disabled={isMaxItems} onClick={increaseCount}>
            +
          </button>
        </div>
      </div>
      <button className={css.bigButton} onClick={handleAddToCart} disabled={disableAddToCart}>
        {buttonLabel}
      </button>
      <button className={isFavorite ? css.likedButton : css.unlikedButton} onClick={toggleFavorites}>
        <svg width="19" height="17" viewBox="0 0 19 17" fill={isFavorite ? '#fff' : '#000'} xmlns="http://www.w3.org/2000/svg">
          <path d="M9.50033 3.08332L9.05033 3.51666C9.10863 3.57714 9.17853 3.62526 9.25584 3.65812C9.33316 3.69098 9.41631 3.70792 9.50033 3.70792C9.58434 3.70792 9.66749 3.69098 9.74481 3.65812C9.82212 3.62526 9.89202 3.57714 9.95033 3.51666L9.50033 3.08332ZM7.35533 13.7683C6.09199 12.7725 4.71116 11.8 3.61533 10.5667C2.54199 9.35666 1.79199 7.94499 1.79199 6.11416H0.541992C0.541992 8.33582 1.46699 10.0308 2.68116 11.3967C3.87283 12.7383 5.39283 13.8133 6.58116 14.75L7.35616 13.7683H7.35533ZM1.79199 6.11416C1.79199 4.32249 2.80449 2.81915 4.18699 2.18666C5.53033 1.57249 7.33533 1.73499 9.05033 3.51666L9.95033 2.65082C7.91699 0.536655 5.55366 0.187488 3.66699 1.04999C1.82199 1.89416 0.541992 3.85416 0.541992 6.11416H1.79199ZM6.58116 14.75C7.00866 15.0867 7.46699 15.445 7.93116 15.7167C8.39533 15.9875 8.92533 16.2083 9.50033 16.2083V14.9583C9.24199 14.9583 8.93866 14.8583 8.56199 14.6375C8.18449 14.4175 7.79366 14.1142 7.35533 13.7683L6.58116 14.75ZM12.4195 14.75C13.6078 13.8125 15.1278 12.7392 16.3195 11.3967C17.5337 10.03 18.4587 8.33582 18.4587 6.11416H17.2087C17.2087 7.94499 16.4587 9.35666 15.3853 10.5667C14.2895 11.8 12.9087 12.7725 11.6453 13.7683L12.4195 14.75ZM18.4587 6.11416C18.4587 3.85416 17.1795 1.89416 15.3337 1.04999C13.447 0.187488 11.0853 0.536655 9.05033 2.64999L9.95033 3.51666C11.6653 1.73582 13.4703 1.57249 14.8137 2.18666C16.1962 2.81915 17.2087 4.32166 17.2087 6.11416H18.4587ZM11.6453 13.7683C11.207 14.1142 10.8162 14.4175 10.4387 14.6375C10.062 14.8575 9.75866 14.9583 9.50033 14.9583V16.2083C10.0753 16.2083 10.6053 15.9875 11.0695 15.7167C11.5345 15.445 11.992 15.0867 12.4195 14.75L11.6453 13.7683Z" fill="black" />
        </svg>
        {<FormattedMessage id={isFavorite ? "AddedToLikesButton.label" : "AddToLikesButton.label"} />}
      </button>
    </div>
  );
};

AddToCartButton.defaultProps = {
  isListingPage: false,
};

export default AddToCartButton;
