import React, { useMemo, useState } from 'react';
import css from './Reviews.module.css';
import Avatar from '../Avatar/Avatar';
import UserDisplayName from '../UserDisplayName/UserDisplayName';
import ReviewRating from '../ReviewRating/ReviewRating';
import { propTypes } from '../../util/types';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import { arrayOf } from 'prop-types';
import ButtonArrowLeft from '../iconsComponent/buttonArrowLeft';
import ButtonArrowRight from '../iconsComponent/buttonArrowRight';

const RatingTextMapper = {
    1: 'Very Bad',
    2: 'Bad',
    3: 'Normal',
    4: 'Good',
    5: 'Very Good'
}

const SORT_OPTIONS = [
    { label: "Newest", value: 'newest' },
    { label: "Lowest", value: 'lowest' },
    { label: "Highest", value: 'highest' },
]

const ReviewsComponent = ({
    reviews,
    intl,
    title = "Reviews",
    showSortBy = false,
    page = ""
}) => {

    const reviewsPerPage = 4;
    const maxPageNumbersToShow = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('newest');

    const handlePrevPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    const calculatedReviews = useMemo(() => {
        if (showSortBy) {
            if (sortBy === 'newest') {
                return reviews?.sort((a, b) =>
                    new Date(b?.attributes?.createdAt) - new Date(a?.attributes?.createdAt)
                );
            }
            if (sortBy === 'lowest') {
                return reviews?.sort((a, b) =>
                    a?.attributes?.rating - b?.attributes?.rating
                );
            }
            if (sortBy === 'highest') {
                return reviews?.sort((a, b) =>
                    b?.attributes?.rating - a?.attributes?.rating
                );
            }
        }
        return reviews;
    }, [reviews, sortBy, showSortBy]);

    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = calculatedReviews.slice(indexOfFirstReview, indexOfLastReview);
    const totalPages = Math.ceil(calculatedReviews.length / reviewsPerPage);
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    const adjustedStartPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);

    return (
        <div className={css.container}>
            <div className={css.reviewsMainContainer}>
                <div className={`${css.reviewsSection} ${page === "ListingPage" ? css.listingPageReviewSection : ""}`}>
                    <div className={css.reviewSectionHead}>
                        <h2>{`${title} (${reviews.length})`}</h2>
                        {
                            showSortBy
                                ? <div className={css.sortBy}>
                                    <p>Sort by:</p>
                                    <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                        {SORT_OPTIONS?.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
                                    </select>
                                </div>
                                : null
                        }
                    </div>
                    {
                        calculatedReviews?.length > 0
                            ? <>
                                <div className={css.reviewsContainer}>
                                    {currentReviews.map(review => {

                                        const { author, attributes: { createdAt, content, rating } } = review || {};
                                        const dateString = intl.formatDate(createdAt, { day: 'numeric', month: 'long', year: 'numeric' });

                                        return (
                                            <div key={review.id} className={css.review}>
                                                <div className={css.profile}>
                                                    <Avatar className={css.avatar} user={author} />
                                                    <div className={css.profileDetails}>
                                                        <UserDisplayName user={author} intl={intl} className={css.profileName} />
                                                        <p className={css.reviewDate}>{dateString}</p>
                                                        <div className={css.ratingStatusText}>
                                                            <ReviewRating
                                                                rating={rating}
                                                                className={css.mobileReviewRating}
                                                                reviewStarClassName={css.reviewRatingStar}
                                                            />
                                                            <p className={css.reviewTextStar}>{RatingTextMapper[rating] || RatingTextMapper[3]}</p>
                                                        </div>
                                                        <p className={css.reviewText}>{content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={css.pagination}>
                                    <button className={css.arrow} onClick={handlePrevPage} disabled={currentPage === 1}>
                                       <ButtonArrowLeft />
                                    </button>
                                    {Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => {
                                        const pageNumber = adjustedStartPage + index;
                                        return (
                                            <button
                                                key={pageNumber}
                                                className={pageNumber === currentPage ? css.active : ''}
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >
                                                {pageNumber}
                                            </button>
                                        );
                                    })}
                                    <button className={css.arrow} onClick={handleNextPage} disabled={currentPage === totalPages}>
                                        <ButtonArrowRight />
                                    </button>
                                </div>
                            </>
                            : null
                    }
                </div>
            </div>
        </div>
    );
};



ReviewsComponent.defaultProps = {
    reviews: [],
    page: null
};

ReviewsComponent.propTypes = {
    reviews: arrayOf(propTypes.review),
    intl: intlShape.isRequired,
    page: propTypes.string
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;

