import React from 'react';
import css from './OurStory.module.css';
import { propTypes } from '../../util/types';
import { injectIntl } from 'react-intl';
import OUR_STORY from '../../assets/ourStory.png'

const OurStoryComponent = ({ user }) => {

    const { store_story = "", store_photo = "" } = user?.attributes?.profile?.publicData || {};
    const sectionHaveContent = store_story || store_photo;

    if (!sectionHaveContent) return null;

    return (
        <div className={css.container}>
            <div className={css.storySections}>
                <div className={css.textSection}>
                    <h2 className={css.heading}>Our Story</h2>
                    {store_story
                        ? <p className={css.description}>
                            {store_story?.slice(0, 775)}
                        </p>
                        : null
                    }
                </div>
                <div className={css.imageSection}>
                    {<img className={css.storeImage} src={store_photo || OUR_STORY} />}
                </div>
            </div>
        </div>
    );
};

OurStoryComponent.defaultProps = {
    user: null,
};

OurStoryComponent.propTypes = {
    user: propTypes.user,
};

const OurStory = injectIntl(OurStoryComponent);

export default OurStory;
