import React from 'react';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { bool, number, object, string, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { getMapProviderApiAccess } from '../../util/maps';
import * as mapboxMap from './MapboxMap';
import * as googleMapsMap from './GoogleMap';
import LandingPageLocationsMap from './LandingPageLocationsMap';
import SellerSelectLocationMap from './SellerSelectLocationMap';
import css from './Map.module.css';

export const Map = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    mapRootClassName,
    address,
    center,
    obfuscatedCenter,
    zoom,
    mapsConfig,
    useStaticMap,
    onLocationSelect,
    isSellerSelect,
    isLandingPage,
    locations,
  } = props;
  const mapsConfiguration = mapsConfig || config.maps;
  const hasApiAccessForMapProvider = !!getMapProviderApiAccess(
    mapsConfiguration
  );
  const isGoogleMapsInUse = mapsConfiguration.mapProvider === 'googleMaps';
  const StaticMap = isGoogleMapsInUse
    ? googleMapsMap.StaticMap
    : mapboxMap.StaticMap;
  const DynamicMap = isGoogleMapsInUse
    ? googleMapsMap.DynamicMap
    : mapboxMap.DynamicMap;
  const isMapsLibLoaded = isGoogleMapsInUse
    ? googleMapsMap.isMapsLibLoaded
    : mapboxMap.isMapsLibLoaded;

  const classes = classNames(rootClassName || css.root, className);
  const mapClasses = mapRootClassName || css.mapRoot;

  if (mapsConfiguration.fuzzy.enabled && !obfuscatedCenter) {
    throw new Error(
      'Map: obfuscatedCenter prop is required when config.maps.fuzzy.enabled === true'
    );
  }
  if (!mapsConfiguration.fuzzy.enabled && !center) {
    throw new Error(
      'Map: center prop is required when config.maps.fuzzy.enabled === false'
    );
  }

  const location = mapsConfiguration.fuzzy.enabled ? obfuscatedCenter : center;
  const zoomLevel =
    zoom || mapsConfiguration.fuzzy.enabled
      ? mapsConfiguration.fuzzy.defaultZoomLevel
      : 11;

  const isMapProviderAvailable =
    hasApiAccessForMapProvider && isMapsLibLoaded();
  return !isMapProviderAvailable ? (
    <div className={classes} />
  ) : useStaticMap ? (
    <StaticMap
      center={location}
      zoom={zoomLevel}
      address={address}
      mapsConfig={mapsConfiguration}
    />
  ) : isSellerSelect ? (
    <SellerSelectLocationMap
      containerClassName={classes}
      mapClassName={mapClasses}
      center={location}
      zoom={zoomLevel}
      address={address}
      mapsConfig={mapsConfiguration}
      onLocationSelect={onLocationSelect}
    />) : isLandingPage ? (
      <LandingPageLocationsMap
        center={location}
        zoom={zoomLevel}
        address={address}
        mapsConfig={mapsConfiguration}
        locations={locations}
      />
    ) : (
    <DynamicMap
      containerClassName={classes}
      mapClassName={mapClasses}
      center={location}
      zoom={zoomLevel}
      address={address}
      mapsConfig={mapsConfiguration}
      onLocationSelect={onLocationSelect}
    />
  );
};

Map.defaultProps = {
  className: null,
  rootClassName: null,
  mapRootClassName: null,
  address: '',
  zoom: null,
  mapsConfig: null,
  useStaticMap: false,
};

Map.propTypes = {
  className: string,
  rootClassName: string,
  mapRootClassName: string,
  address: string,
  center: propTypes.latlng,
  obfuscatedCenter: propTypes.latlng,
  zoom: number,
  mapsConfig: object,
  useStaticMap: bool,
  onLocationSelect: func,
};

export default Map;
