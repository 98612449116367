const sizeOrder = ['XXS', 'XS', 'S', 'Small', 'M', 'Medium', 'L', 'Large', 'XL', 'XXL'];

export const sortSize = sizes => {
  const allAreNumbers = sizes.every(size => !isNaN(size.description));
  return sizes.sort((a, b) => {
    if (allAreNumbers) {
      return parseInt(a.description) - parseInt(b.description);
    }
    const aIndex = sizeOrder.indexOf(a.description);
    const bIndex = sizeOrder.indexOf(b.description);
    return aIndex - bIndex;
  });
};
